import {
  Box,
  Container,
  Fab,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import { media_url } from "src/coreConfig";
import useBlog from "src/hooks/useBlog";
import { useSelector } from "src/store";

const BlogIndex = () => {
  const { pages, banners, menu, snippets, loaded } = useSelector(
    (state) => state.blog
  );
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { getPosts } = useBlog();
  const { t } = useTranslation();
  const [blogState, setBlogState] = React.useState({
    page: 1,
    posts: [],
    loading: false,
    currentBanner: 0,
  });
  const { posts, page, loading, currentBanner } = blogState;

  React.useEffect(() => {
    getPosts(page).then((data) => {
      setBlogState((preState) => ({
        ...preState,
        posts: data,
        loading: false,
      }));
    });
  }, []);

  React.useEffect(() => {}, [currentBanner]);

  if (!pages || !posts || !banners || !menu) return null;
  console.log(snippets[0]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: 4,
        }}
      >
        <Carousel
          width="100%"
          height={isSmall ? 150 : 500}
          autoPlay={true}
          animation="slide"
        >
          {banners.map((banner) => (
            <Container
              key={banner.id}
              sx={{
                backgroundImage: `url(${media_url(banner.image)})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",

                backgroundPosition: "center",
                width: "100%",
                height: "100%",
                display: "flex",
              }}
            >
              <Box sx={{ minWidth: 450 }}></Box>
            </Container>
          ))}
        </Carousel>
      </Container>

      <Grid
        container
        spacing={2}
        // centered
        sx={{
          padding: 2,
          marginTop: 4,
          marginBottom: 4,
          display: "flex",
          flexDirection: isSmall ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {snippets.map((snippet) => (
          <div
            key={snippet.id}
            dangerouslySetInnerHTML={{ __html: snippet.html }}
            style={{ margin: 10 }}
          />
        ))}
        <Grid item sx={{ width: 0, height: 0, overflow: "hidden" }}>
          <Link href="#" target="_blank">
            <Fab variant="extended" color="secondary">
              {" "}
            </Fab>
          </Link>
        </Grid>
        <Grid item sx={{ width: 0, height: 0, overflow: "hidden" }}>
          <Fab variant="extended" color="success"></Fab>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {posts.map((post) => (
          <Grid item key={post.id} xs={12} md={6} lg={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <img
                src={media_url(post.image)}
                alt={post.title}
                style={{ width: "100%", height: 200, objectFit: "cover" }}
              />
              <Box sx={{ padding: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginBottom: 2 }}
                >
                  {post.title}
                </Typography>
                <Typography>{post.description}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogIndex;
